<template>
  <div class="home">
    <Head recruit="true"></Head>
    <div class="main">
      <div class="introduce">
        <div class="content fcs">
          <div class="left fcs">
            <div class="headPortrait">
              <img
                :src="showInfo.jobSeekerBasicInfoDO.headImgUrl"
                alt=""
              />
            </div>
            <ul>
              <li>{{showInfo.jobSeekerBasicInfoDO.name}}</li>
              <li>
                <p><span>{{showInfo.jobSeekerBasicInfoDO.age}}岁</span><span>{{showInfo.jobSeekerBasicInfoDO.jobExperience}}年</span><span>{{showInfo.jobSeekerBasicInfoDO.educationMaxNme}}</span></p>
                <p >
                  <span
                    ><img
                      src="@/assets/img/Index_JobSeeker/icon22.png"
                      width="18"
                      alt=""
                    />{{showInfo.jobSeekerBasicInfoDO.phone}}</span
                  >
                  <span
                    ><img
                      src="@/assets/img/Index_JobSeeker/icon23.png"
                      width="18"
                      alt=""
                    />{{showInfo.jobSeekerBasicInfoDO.email}}</span
                  >
                </p>
              </li>
            </ul>
          </div>
          <div class="right">
            <el-button round class="Collection" @click="changeIcon()"
              ><img :src="imgUrl" alt="" /> 收藏</el-button
            >
            <el-button round @click="$router.push('/chart')">联系客服</el-button>
          </div>
        </div>
      </div>
      <div class="content show">
        <div class="left">
          <ul class="jlxq">
            <li>
              <a href="#xx">个人信息</a>
            </li>
            <li>
              <a  @click="goto('ys')">个人优势</a>
            </li>
            <li>
              <a  @click="goto('yw')">求职愿望</a>
            </li>
            <li>
              <a @click="goto('gz')" >工作经历</a>
            </li>
            <li>
              <a  @click="goto('xm')">项目经历</a>
            </li>
            <li>
              <a @click="goto('jy')" >教育经历</a>
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="item">
            <div class="title" id="ys">个人优势</div>
            <div>
              <p v-for="(item, idx) in showInfo.jobSeekerBasicInfoDO.personalAdvantage && showInfo.jobSeekerBasicInfoDO.personalAdvantage.split('\n')" :key="idx">
                {{item}}
              </p>
            </div>
          </div>

          <div class="item">
            <div class="title" id="yw">求职愿望</div>
            <div>
              {{showInfo.jobExpectationDO.expectedPositionName}}   |   {{showInfo.jobExpectationDO.expectedPositionTypeName}}   |   {{showInfo.jobExpectationDO.cityName}}   |   {{showInfo.jobExpectationDO.expectedSalaryMin}}
            </div>
          </div>

          <div class="item">
            <div class="title" id="gz">工作经历</div>
            <div v-for="item in showInfo.workExperienceDOS" :key="item.id">
              <div class="gs">
                <div class="text">
                  <div class="bt">{{item.corporateName}}</div>
                  <p>{{item.companyIndustryName}}   |   {{item.positionName}}</p>
                  <p class="timer">
                    {{ item.jobStartTime + ' - ' + item.jobEndTime }}
                  </p>
                </div>
              </div>
              <div>
                <p v-for="(item,idx) in item.jobContent && item.jobContent.split('\n')" :key="idx">
                  {{item}}
                </p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="title" id="xm">项目经历</div>
            <div v-for="item in showInfo.projectExperienceDOS" :key="item.id" class="projectBox">
              <div>
                <h5>{{item.projectName}}</h5>
                <p class="timer">
                  {{ item.startTime + ' - ' + item.endTime }}
                </p>
                <div>
                  <p v-for="(item,idx) in item.projectContent && item.projectContent.split('\n')" :key="idx">
                  {{item}}
                </p>
                </div>
              </div>
              <br>
              <div>
                <p v-for="(item,idx) in item.jobDuty && item.jobDuty.split('\n')" :key="idx">
                  {{item}}
                </p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="title" id="jy">教育经历</div>
            <div v-for="item in showInfo.educationalExperienceDOS" :key="item.id">
              <div class="gs">
                <div class="text">
                  <div class="bt">{{item.schoolName}}</div>
                  <p>{{item.educationName+'.'+item.educationSystemTypeName}}   |   {{item.professionalName}}</p>
                  <p class="timer">
                    {{ item.schoolStartTime + ' - ' + item.schoolEndTime }}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { jobDetail, customer, jobDetail2 } from '@/api/zp'
import {
  collectionAdd,
  collectionRemove2,
} from "@/api/index";
import Head from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Recruit',
  data() {
    return {
      imgUrl:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAwCAYAAAC4wJK5AAAABHNCSVQICAgIfAhkiAAABL9JREFUaEPtWUt62kgQrhIGnNXgzcyHN3FOMJ4T2D6ByQlCThBzApoThJwgzgnCnCD4BPGcIMzGzMwmZBVbjun5S62GFkigJ3l80QZJqKvrr1fXg+kHuPgHwEA/QXwrWtyuCTU9ajKfk9YdMH3qMq5Jz/A8JuZrX9MbUu1JKmDqY6vOt+cMmkzcAp0Wfo+xVtZPtPwyj3zdvCJ1IHtsvJJByEb0ue8RX2wjYv/H5pc+0SARDATSIOpj025qmkzK1/uvNoGJBbGnpqce6bciJdkMkvpTE4/uaX8UIRYw5UGCc0iUnkXB7Pfcb5uDf15oPVerNEXqTLXZvfr1mkBvj+ioRho0WTR/Eu4/w/69e9W+jAO/BqKupl2P6HX48dWdSC2NmRgpg0kDBsBHvjp8KvcNNX1tpS8C8UW7KWk2oV0LZk56eK8Oe6tAIiBcAJDQwFdtlVbt9ru6+u+Y9cOYmX4R85L3AkBr+kTsdX312yg7zaVgA5NV7ecujQUIMaEa0Tv5c070PEl1aRhwgQRaAQDNtdPAZHJem/gzIODEDfr8wUSKfBpY5S2qEe9pHg2s01xqBGb+xJpkAAI2K7bcx+3VnWqf5hTW+jL4SZ1qrSIaWAdyM0TEfIH3Y/B6ZswVWmjS7Ud5eCA6+6La49JAVEFIrEbfTsTn5lT7QwTEjjOXq4UqAIQ068poA9HqFaLVBTfUzQi+cA73692pw2GFe5dG2nHyCUzqCTfVVMzn5LswJUcM4BsxiAggWECYB9o/SJOnlCbOgoQa/elM/EKi1BIEEBWku9PlrgV9tyDgy+8l8xU3EMe+xsPvNlztVJwFNov4hI1ORVONAvxkXyo1DtEHSWf8QbsFc7pBvcAv4d1vkFh1s1Pc/Qp7tgUZsTpEGWBRoUrDi4Pds5R9x1XrCXMn6xfFstfs7ORY4aRJ9lgIQFiTwu0iqcpBfidLbLJqTUk2XabiYVKlqZy0uRJETsngZhiLA85JBIN8pBImChK1iR/IRJLVyCmN2DvBB4/LKowK8hxZLkWWRw/v5aVbEC3NKfzcLQG/qYTQOLMAOLLpt4twLV9yHGfm0yOUgNubV2VKPI4WQqq0jzpw5r9wDEiTLXLFJn02ucKiawA5+5pAFoexnM5Mx3GtnvjM1ahPOhOPv2bYjbaQkqNmYvq92j9a7fVUbUIugG153cYaYg0IRVuTVQHJAmAtOsUxFQVSvY80BtM+a1LCS1wkiuMxVTUXAKEvl1J3SDtf095Zmb2kgLHgNL59aXu220xoY4hNNBHj7NJHPTFAkrvUmc3MpBPvpFKTGmHO1MnS/0qlCZcpnCPQSNj5TjE72Aaoof5FC/8BXfNgjPA3KsxOVi1nBiFMrbT/Mdnxepl7rUb6wrzMIYIZCM4kjBGyH665QBggSz8JmRhh7oDByvaRV30wfYZR1zBoYBdo+Vst5wZhCciJqjUrM4/ADI95mDSeEuY9E3mOiko/n2NvMm4TWSDZxZQIw0IeyfBwznrGc91B71TMJmBebB8meJHZBBN4KKwJl24467swvd3YC45LqsgAJ45qqSAWG5j5XRfuivMlGNyMwfw4S9jcFtXKN6csO1bwbTWaqIDRTSR/gtixwBO3+x/In3Qsy/jSjgAAAABJRU5ErkJggg==",
      showInfo: {
        jobSeekerBasicInfoDO: {},
        jobExpectationDO: {},
        educationalExperienceDOS: {},
        workExperienceDOS: {},
        projectExperienceDOS:{}
      },
      userInfo: {},
    };
  },
  components: {
    Head,
    Footer,
  },
  created(){
    if(this.$route.query.cs){
      jobDetail2(this.$route.query.id).then(res => {
        this.showInfo = res.data
        if(this.showInfo.hasCollection === '1'){
          this.imgUrl =
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAABGlJREFUaEPtWW2IFVUYft9x7sy6UaCZ7cxqRWVgCGWghlBKZEEIQQRFhGQh9rE/1D4sse65q8sWoQRRP8L60f4LKsGgMtB+FBkUGJhf0GbFzL3uJqSIMXPvzumZ6727e3fvvfO5jpc8sDAz932f93nOx3vec5apwxt3OH+6IiDrEfx/jECuv7SCPe9hIrkaihcQ8UL0fEmSHMbzKWI+6UpliMT1eA/Rdv81O3dOXa+QfAwYBjzmMfEc4FuSGH90SDLvreSNg0FobUcgJ4rPM3mbAL4oCKj2+5cIvsUV5vGm9kJ2aWS/RpJeYOZrQ2AOA+9l4H3Wyra5AHFmgSad/cy0OESQZiYvOsLcPfkHXRQfQg+/j283RsXEKO1zqXsdiTn/TPWdJkDfMbKIxsoHMDUwVRI05lecvPG2j5ArFJ9TZJV8/Cbpd0yrta4wjk4GaRSw01qoV+gQyJvxI014esxPKUQjUsovEAiPyRpwzrisLp+81hoEaML+ER+WJwvT6I05fAGY3WlhYjr94oreO+t44wJyBesZRfKetALNJA5Gtq+cN97zY1wU8ImcpR8t/hZngc0k0dbY0nYM8ybayOWqAF2U1hJ5+7IhEy+qZHrUzZufVgVowvoIuX59PKhsvLC2hrA/rKsLOAwBd2RDJV5UCPgVApbUBNhn8XBNPKhsvPzsBgFXMUnJeqHoZUMjWVTndkOtCtAKxUoaG00yOtG8MQISI6DUspA9Cvd50SAyt/4b9dZ1dQH7QWdN5pQiEMCO/AN25JV1AVvguyuCf+amWLS7ysJ86eJOLErzNfJKeOmYExoELIOAn8YJ68L2Tz+rM+/acASGMf9v8U0nijlhPa0QfxjOP1srlBHbUUYMNAggIVWd7GPQdGu29AKjjzqk3kxi/vlGAXjrhKLOI7mhLHrHy/7pR0phfw0tDwT2QwYGSJ2HkTqXTg49PesMjBpa2T2C4m5uBhxbhqye7GbJu5zXe0+0F4Bf1X77HsWjby+n8gIL9xEs3M+nKmyZ97WCtZUlv3k5jAJy/jvI+ZubcWm7ceGQPwSDJ7MVIQ84ZK4hwU0r5vY7r5AKUus3SK33ZSEC8/6IS7SChHmhVfzg0kHY3RrJ77CoG1b/JRD0p0PKMhI9I+1iBQvwvd8avVr/t+wf+lddAuJ+iFOOirJmu/lHULxwAnwUTCdczO6Z6cM/ps1xV+N7aZvhn1ECW3gBNaicsPpQM70biBzP4Cunq+txenXu2bDukQX4wKqw7ocI/04mtYsA9PwgjojbwhKv28US4DtrO+zFNCb3YkrdFjXoFPvTY6Q8URE9uBGP3mILqIfCXjEAkMg9V/P/wKHZW5vd+4eVkliAHyjXby1VPB7E44NhAmO6/CxJ6SuLHlzlJ2upCKhTUPuLqxTPwzmVcdc6vfnEcYQa9O80k9Ge8E5VwDhs3r5BY3oW4Hfjm/+PjRNjivy48kbv92kRT7yI0yYSF29mRiAumxh+VwTE6LRUXTp+BP4DDzlN7PQGDKIAAAAASUVORK5CYII="
        }
      })
    } else if(this.$route.query.kf){
      customer(this.$route.query.id).then(res => {
        this.showInfo = res.data
        if(this.showInfo.hasCollection === '1'){
          this.imgUrl =
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAABGlJREFUaEPtWW2IFVUYft9x7sy6UaCZ7cxqRWVgCGWghlBKZEEIQQRFhGQh9rE/1D4sse65q8sWoQRRP8L60f4LKsGgMtB+FBkUGJhf0GbFzL3uJqSIMXPvzumZ6727e3fvvfO5jpc8sDAz932f93nOx3vec5apwxt3OH+6IiDrEfx/jECuv7SCPe9hIrkaihcQ8UL0fEmSHMbzKWI+6UpliMT1eA/Rdv81O3dOXa+QfAwYBjzmMfEc4FuSGH90SDLvreSNg0FobUcgJ4rPM3mbAL4oCKj2+5cIvsUV5vGm9kJ2aWS/RpJeYOZrQ2AOA+9l4H3Wyra5AHFmgSad/cy0OESQZiYvOsLcPfkHXRQfQg+/j283RsXEKO1zqXsdiTn/TPWdJkDfMbKIxsoHMDUwVRI05lecvPG2j5ArFJ9TZJV8/Cbpd0yrta4wjk4GaRSw01qoV+gQyJvxI014esxPKUQjUsovEAiPyRpwzrisLp+81hoEaML+ER+WJwvT6I05fAGY3WlhYjr94oreO+t44wJyBesZRfKetALNJA5Gtq+cN97zY1wU8ImcpR8t/hZngc0k0dbY0nYM8ybayOWqAF2U1hJ5+7IhEy+qZHrUzZufVgVowvoIuX59PKhsvLC2hrA/rKsLOAwBd2RDJV5UCPgVApbUBNhn8XBNPKhsvPzsBgFXMUnJeqHoZUMjWVTndkOtCtAKxUoaG00yOtG8MQISI6DUspA9Cvd50SAyt/4b9dZ1dQH7QWdN5pQiEMCO/AN25JV1AVvguyuCf+amWLS7ysJ86eJOLErzNfJKeOmYExoELIOAn8YJ68L2Tz+rM+/acASGMf9v8U0nijlhPa0QfxjOP1srlBHbUUYMNAggIVWd7GPQdGu29AKjjzqk3kxi/vlGAXjrhKLOI7mhLHrHy/7pR0phfw0tDwT2QwYGSJ2HkTqXTg49PesMjBpa2T2C4m5uBhxbhqye7GbJu5zXe0+0F4Bf1X77HsWjby+n8gIL9xEs3M+nKmyZ97WCtZUlv3k5jAJy/jvI+ZubcWm7ceGQPwSDJ7MVIQ84ZK4hwU0r5vY7r5AKUus3SK33ZSEC8/6IS7SChHmhVfzg0kHY3RrJ77CoG1b/JRD0p0PKMhI9I+1iBQvwvd8avVr/t+wf+lddAuJ+iFOOirJmu/lHULxwAnwUTCdczO6Z6cM/ps1xV+N7aZvhn1ECW3gBNaicsPpQM70biBzP4Cunq+txenXu2bDukQX4wKqw7ocI/04mtYsA9PwgjojbwhKv28US4DtrO+zFNCb3YkrdFjXoFPvTY6Q8URE9uBGP3mILqIfCXjEAkMg9V/P/wKHZW5vd+4eVkliAHyjXby1VPB7E44NhAmO6/CxJ6SuLHlzlJ2upCKhTUPuLqxTPwzmVcdc6vfnEcYQa9O80k9Ge8E5VwDhs3r5BY3oW4Hfjm/+PjRNjivy48kbv92kRT7yI0yYSF29mRiAumxh+VwTE6LRUXTp+BP4DDzlN7PQGDKIAAAAASUVORK5CYII="
        }
      })
    } else {
      this.getJobDetail()
    }
  },
  mounted() {
    const userInfo = window.sessionStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    }


  },
  methods: {
    goto(el){
      setTimeout(() => {
        document.documentElement.scrollTop = document.getElementById(el).offsetTop - 180
      })
    },
    // 详情
    getJobDetail(){
      jobDetail(this.$route.query.id).then(res => {
        this.showInfo = res.data
        if(this.showInfo.hasCollection === '1'){
          this.imgUrl =
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAABGlJREFUaEPtWW2IFVUYft9x7sy6UaCZ7cxqRWVgCGWghlBKZEEIQQRFhGQh9rE/1D4sse65q8sWoQRRP8L60f4LKsGgMtB+FBkUGJhf0GbFzL3uJqSIMXPvzumZ6727e3fvvfO5jpc8sDAz932f93nOx3vec5apwxt3OH+6IiDrEfx/jECuv7SCPe9hIrkaihcQ8UL0fEmSHMbzKWI+6UpliMT1eA/Rdv81O3dOXa+QfAwYBjzmMfEc4FuSGH90SDLvreSNg0FobUcgJ4rPM3mbAL4oCKj2+5cIvsUV5vGm9kJ2aWS/RpJeYOZrQ2AOA+9l4H3Wyra5AHFmgSad/cy0OESQZiYvOsLcPfkHXRQfQg+/j283RsXEKO1zqXsdiTn/TPWdJkDfMbKIxsoHMDUwVRI05lecvPG2j5ArFJ9TZJV8/Cbpd0yrta4wjk4GaRSw01qoV+gQyJvxI014esxPKUQjUsovEAiPyRpwzrisLp+81hoEaML+ER+WJwvT6I05fAGY3WlhYjr94oreO+t44wJyBesZRfKetALNJA5Gtq+cN97zY1wU8ImcpR8t/hZngc0k0dbY0nYM8ybayOWqAF2U1hJ5+7IhEy+qZHrUzZufVgVowvoIuX59PKhsvLC2hrA/rKsLOAwBd2RDJV5UCPgVApbUBNhn8XBNPKhsvPzsBgFXMUnJeqHoZUMjWVTndkOtCtAKxUoaG00yOtG8MQISI6DUspA9Cvd50SAyt/4b9dZ1dQH7QWdN5pQiEMCO/AN25JV1AVvguyuCf+amWLS7ysJ86eJOLErzNfJKeOmYExoELIOAn8YJ68L2Tz+rM+/acASGMf9v8U0nijlhPa0QfxjOP1srlBHbUUYMNAggIVWd7GPQdGu29AKjjzqk3kxi/vlGAXjrhKLOI7mhLHrHy/7pR0phfw0tDwT2QwYGSJ2HkTqXTg49PesMjBpa2T2C4m5uBhxbhqye7GbJu5zXe0+0F4Bf1X77HsWjby+n8gIL9xEs3M+nKmyZ97WCtZUlv3k5jAJy/jvI+ZubcWm7ceGQPwSDJ7MVIQ84ZK4hwU0r5vY7r5AKUus3SK33ZSEC8/6IS7SChHmhVfzg0kHY3RrJ77CoG1b/JRD0p0PKMhI9I+1iBQvwvd8avVr/t+wf+lddAuJ+iFOOirJmu/lHULxwAnwUTCdczO6Z6cM/ps1xV+N7aZvhn1ECW3gBNaicsPpQM70biBzP4Cunq+txenXu2bDukQX4wKqw7ocI/04mtYsA9PwgjojbwhKv28US4DtrO+zFNCb3YkrdFjXoFPvTY6Q8URE9uBGP3mILqIfCXjEAkMg9V/P/wKHZW5vd+4eVkliAHyjXby1VPB7E44NhAmO6/CxJ6SuLHlzlJ2upCKhTUPuLqxTPwzmVcdc6vfnEcYQa9O80k9Ge8E5VwDhs3r5BY3oW4Hfjm/+PjRNjivy48kbv92kRT7yI0yYSF29mRiAumxh+VwTE6LRUXTp+BP4DDzlN7PQGDKIAAAAASUVORK5CYII="
        }
        const m7CardInfo = {
        "right1": {
              "text": this.showInfo.jobSeekerBasicInfoDO.name + '  ' + this.showInfo.jobSeekerBasicInfoDO.jobExperience+'年',  // 首行文字内容，展示时超出两行隐藏，卡片上单行隐藏
              "color": "#333",                 // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#595959
              "fontSize": 16                      // 字体大小， 默认12 ， 请传入number类型的数字
          },
          "right2": {
              "text": this.showInfo.jobExpectationDO.expectedPositionName + '   ' + this.showInfo.jobExpectationDO.cityName,        // 第二行文字内容，展示时超出两行隐藏，卡片上单行隐藏
              "color": "#666666",                 // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#595959
              "fontSize": 14                      // 字体大小， 默认12 ， 请传入number类型的数字
          },
          "right3": {
              "text": this.showInfo.jobExpectationDO.expectedSalaryMin,           // 第三行文字内容，展示时超出两行隐藏，卡片上单行隐藏
              "color": "#999999",                 // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#ff6b6b
              "fontSize": 12                      // 字体大小， 默认14 ， 请传入number类型的数字
          },
          "extraInfos": ["随时随地－连接企业与用户"],             // 额外信息，访客不可见，座席点击卡片上的更多可见，可不填，字符串形式的文本数组
          "url": "http://danlingzp.com/recruit/show?kf=true&id=" + this.showInfo.jobExpectationDO.jobSeekerId                     // 点击可跳转的链接
      }
      sessionStorage.setItem('m7CardInfo', JSON.stringify(m7CardInfo))
      })
    },
    // 切换收藏图标
    changeIcon() {
      let btn = document.getElementsByClassName("Collection")[0];
      btn.classList.toggle("activity");
      if (document.getElementsByClassName("activity")[0]&&(this.showInfo.hasCollection === '0'||this.showInfo.hasCollection === null)) {
        collectionAdd({ content: this.showInfo.jobExpectationDO.jobSeekerId, type: 2 }).then((res) => {
          this.imgUrl =
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAABGlJREFUaEPtWW2IFVUYft9x7sy6UaCZ7cxqRWVgCGWghlBKZEEIQQRFhGQh9rE/1D4sse65q8sWoQRRP8L60f4LKsGgMtB+FBkUGJhf0GbFzL3uJqSIMXPvzumZ6727e3fvvfO5jpc8sDAz932f93nOx3vec5apwxt3OH+6IiDrEfx/jECuv7SCPe9hIrkaihcQ8UL0fEmSHMbzKWI+6UpliMT1eA/Rdv81O3dOXa+QfAwYBjzmMfEc4FuSGH90SDLvreSNg0FobUcgJ4rPM3mbAL4oCKj2+5cIvsUV5vGm9kJ2aWS/RpJeYOZrQ2AOA+9l4H3Wyra5AHFmgSad/cy0OESQZiYvOsLcPfkHXRQfQg+/j283RsXEKO1zqXsdiTn/TPWdJkDfMbKIxsoHMDUwVRI05lecvPG2j5ArFJ9TZJV8/Cbpd0yrta4wjk4GaRSw01qoV+gQyJvxI014esxPKUQjUsovEAiPyRpwzrisLp+81hoEaML+ER+WJwvT6I05fAGY3WlhYjr94oreO+t44wJyBesZRfKetALNJA5Gtq+cN97zY1wU8ImcpR8t/hZngc0k0dbY0nYM8ybayOWqAF2U1hJ5+7IhEy+qZHrUzZufVgVowvoIuX59PKhsvLC2hrA/rKsLOAwBd2RDJV5UCPgVApbUBNhn8XBNPKhsvPzsBgFXMUnJeqHoZUMjWVTndkOtCtAKxUoaG00yOtG8MQISI6DUspA9Cvd50SAyt/4b9dZ1dQH7QWdN5pQiEMCO/AN25JV1AVvguyuCf+amWLS7ysJ86eJOLErzNfJKeOmYExoELIOAn8YJ68L2Tz+rM+/acASGMf9v8U0nijlhPa0QfxjOP1srlBHbUUYMNAggIVWd7GPQdGu29AKjjzqk3kxi/vlGAXjrhKLOI7mhLHrHy/7pR0phfw0tDwT2QwYGSJ2HkTqXTg49PesMjBpa2T2C4m5uBhxbhqye7GbJu5zXe0+0F4Bf1X77HsWjby+n8gIL9xEs3M+nKmyZ97WCtZUlv3k5jAJy/jvI+ZubcWm7ceGQPwSDJ7MVIQ84ZK4hwU0r5vY7r5AKUus3SK33ZSEC8/6IS7SChHmhVfzg0kHY3RrJ77CoG1b/JRD0p0PKMhI9I+1iBQvwvd8avVr/t+wf+lddAuJ+iFOOirJmu/lHULxwAnwUTCdczO6Z6cM/ps1xV+N7aZvhn1ECW3gBNaicsPpQM70biBzP4Cunq+txenXu2bDukQX4wKqw7ocI/04mtYsA9PwgjojbwhKv28US4DtrO+zFNCb3YkrdFjXoFPvTY6Q8URE9uBGP3mILqIfCXjEAkMg9V/P/wKHZW5vd+4eVkliAHyjXby1VPB7E44NhAmO6/CxJ6SuLHlzlJ2upCKhTUPuLqxTPwzmVcdc6vfnEcYQa9O80k9Ge8E5VwDhs3r5BY3oW4Hfjm/+PjRNjivy48kbv92kRT7yI0yYSF29mRiAumxh+VwTE6LRUXTp+BP4DDzlN7PQGDKIAAAAASUVORK5CYII="
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
      } else {
        let form={
          content:this.showInfo.jobExpectationDO.jobSeekerId,
          type:2,
          userId:this.userInfo.userId
        }
        collectionRemove2(form).then((res) => {
          this.imgUrl ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAwCAYAAAC4wJK5AAAABHNCSVQICAgIfAhkiAAABL9JREFUaEPtWUt62kgQrhIGnNXgzcyHN3FOMJ4T2D6ByQlCThBzApoThJwgzgnCnCD4BPGcIMzGzMwmZBVbjun5S62GFkigJ3l80QZJqKvrr1fXg+kHuPgHwEA/QXwrWtyuCTU9ajKfk9YdMH3qMq5Jz/A8JuZrX9MbUu1JKmDqY6vOt+cMmkzcAp0Wfo+xVtZPtPwyj3zdvCJ1IHtsvJJByEb0ue8RX2wjYv/H5pc+0SARDATSIOpj025qmkzK1/uvNoGJBbGnpqce6bciJdkMkvpTE4/uaX8UIRYw5UGCc0iUnkXB7Pfcb5uDf15oPVerNEXqTLXZvfr1mkBvj+ioRho0WTR/Eu4/w/69e9W+jAO/BqKupl2P6HX48dWdSC2NmRgpg0kDBsBHvjp8KvcNNX1tpS8C8UW7KWk2oV0LZk56eK8Oe6tAIiBcAJDQwFdtlVbt9ru6+u+Y9cOYmX4R85L3AkBr+kTsdX312yg7zaVgA5NV7ecujQUIMaEa0Tv5c070PEl1aRhwgQRaAQDNtdPAZHJem/gzIODEDfr8wUSKfBpY5S2qEe9pHg2s01xqBGb+xJpkAAI2K7bcx+3VnWqf5hTW+jL4SZ1qrSIaWAdyM0TEfIH3Y/B6ZswVWmjS7Ud5eCA6+6La49JAVEFIrEbfTsTn5lT7QwTEjjOXq4UqAIQ068poA9HqFaLVBTfUzQi+cA73692pw2GFe5dG2nHyCUzqCTfVVMzn5LswJUcM4BsxiAggWECYB9o/SJOnlCbOgoQa/elM/EKi1BIEEBWku9PlrgV9tyDgy+8l8xU3EMe+xsPvNlztVJwFNov4hI1ORVONAvxkXyo1DtEHSWf8QbsFc7pBvcAv4d1vkFh1s1Pc/Qp7tgUZsTpEGWBRoUrDi4Pds5R9x1XrCXMn6xfFstfs7ORY4aRJ9lgIQFiTwu0iqcpBfidLbLJqTUk2XabiYVKlqZy0uRJETsngZhiLA85JBIN8pBImChK1iR/IRJLVyCmN2DvBB4/LKowK8hxZLkWWRw/v5aVbEC3NKfzcLQG/qYTQOLMAOLLpt4twLV9yHGfm0yOUgNubV2VKPI4WQqq0jzpw5r9wDEiTLXLFJn02ucKiawA5+5pAFoexnM5Mx3GtnvjM1ahPOhOPv2bYjbaQkqNmYvq92j9a7fVUbUIugG153cYaYg0IRVuTVQHJAmAtOsUxFQVSvY80BtM+a1LCS1wkiuMxVTUXAKEvl1J3SDtf095Zmb2kgLHgNL59aXu220xoY4hNNBHj7NJHPTFAkrvUmc3MpBPvpFKTGmHO1MnS/0qlCZcpnCPQSNj5TjE72Aaoof5FC/8BXfNgjPA3KsxOVi1nBiFMrbT/Mdnxepl7rUb6wrzMIYIZCM4kjBGyH665QBggSz8JmRhh7oDByvaRV30wfYZR1zBoYBdo+Vst5wZhCciJqjUrM4/ADI95mDSeEuY9E3mOiko/n2NvMm4TWSDZxZQIw0IeyfBwznrGc91B71TMJmBebB8meJHZBBN4KKwJl24467swvd3YC45LqsgAJ45qqSAWG5j5XRfuivMlGNyMwfw4S9jcFtXKN6csO1bwbTWaqIDRTSR/gtixwBO3+x/In3Qsy/jSjgAAAABJRU5ErkJggg=="
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.introduce {
  height: 182px;
  background: #edf5ff;
  margin-bottom: 20px;
  .left {
    height: 182px;
    .headPortrait {
      height: 134px;
      width: 134px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    li:nth-child(1) {
      height: 40px;
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
      margin-bottom: 24px;
    }
    li:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: flex;
      img {
        vertical-align: middle;
        margin-right: 8px;
        position: relative;
        top: -2px;
      }
      p:nth-child(1) span {
        padding: 0 16px;
        border-right: 1px solid #666;
      }
      p:nth-child(1) span:nth-child(1) {
        padding-left: 0;
      }
      p:nth-child(1) span:last-child {
        padding-right: 0;
        border-right: none;
      }
      p:nth-child(2) {
        margin-left: 48px;
      }
      p:nth-child(2) span:nth-child(1) {
        margin-right: 24px;
      }
    }
  }
  .right {
    height: 134px;
    display: flex;
    align-items: center;
    /deep/ .el-button {
      width: 160px;
      height: 44px;
      border-radius: 24px;
      border: 1px solid #0780e6;
      font-weight: 400;
      color: #0780e6;
      margin-right: 24px;
      background-color: transparent;
    }
    /deep/ .el-button:nth-child(2) {
      color: #fff;
      background-color: #0780e6;
    }
    /deep/ .el-button img {
      vertical-align: middle;
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }
}
.el-button.is-round {
  padding: 9px 23px;
}
.show{
  overflow: hidden;
  .left{
    float: left;
    width: 280px;
    .fjjl{
      background: #FFFFFF;
      padding: 16px 16px 5px 16px;
      h4{
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      li{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 58px;
        border-bottom: 1px solid #EEEEEE;
        background: transparent url(../../assets/img/recruit/icon-jl.png) no-repeat left center;
        padding-left: 22px;
        &:last-child{
          border: 0;
        }
        a{
          color: #666666;
          display: inline-block;
        }
        .del{
          float: right;
          cursor: pointer;
          display: inline-block;
          width: 16px;
          height: 58px;
          background: transparent url(../../assets/img/recruit/icon-del.png) no-repeat left center;
        }
      }
    }
    .jlxq{
      margin: 0 0 20px;
      background: #fff;
      li{
        line-height: 98px;
        border-bottom: 1px solid #eee;
        &:last-child{
          border: 0;
        }
        text-align: center;
        a{
          display: block;
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .right{
    overflow: hidden;
    margin-left: 300px;
    .item{
      background: #fff;
      padding: 16px 16px 20px 16px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
      margin-bottom: 20px;
      h5{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .zy{
        color: #333333;
      }
      .title{
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        position: relative;
        padding-left: 14px;
        margin-bottom: 16px;
        background: transparent url('../../assets/img/recruit/icon-xq.png') no-repeat right center;
        &::after{
          position: absolute;
          content: '';
          display: block;
          width: 6px;
          height: 16px;
          background: #0780E6;
          left: 0;
          top: 6px;
        }
        &::before{
          position: absolute;
          content: '';
          display: block;
          height: 0;
          border-bottom: 1px dashed #ccc;
          left: 110px;
          right: 40px;
          top: 14px;
        }
      }
      .gs{
        overflow: hidden;
        margin-bottom: 13px;
        margin-top: 20px;
        img{
          float: left;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-right: 16px;
        }
        .text{
          overflow: hidden;
          .bt{
            margin-top: 7px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 16px;
          }
          p{
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            margin: 8px 0;
          }
        }
      }
    }
  }
  .timer {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin: 8px 0;
  }
  .projectBox{
    margin-top: 30px;
  }
}
</style>
